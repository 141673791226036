import React from 'react';
import './productCard.css';
import { Product } from '../services/models';
import { Card } from 'react-bootstrap';

type productCardProps = {
    product: Product,
    handleOpenModal(product)
}

export default function productCard({ product, handleOpenModal }: productCardProps) {

    return (
        <Card className="col m-3 product-card" key={product.id} onClick={(e) => { handleOpenModal(product) }} style={{ minHeight: '30px' }}>
            <Card.Img variant="top" src={product.imageUrls[0]} />
            <Card.Body>
                <Card.Title>{product.name}</Card.Title>
                {product.outOfStock ?
                    (
                        <Card.Footer>
                            <span className='text-danger'>Out of stock</span>
                        </Card.Footer>
                    ) : (
                        '£' + product.price
                    )}
            </Card.Body>
        </Card>
    );
};