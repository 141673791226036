import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { BsPerson } from 'react-icons/bs';
import '../bootstrap.min.css';
import './navbar.css';
import Cart from './cart';

type TopNavBarProps = {
  cartItems: any[];
  removeItemFromCart: (index: number) => void;
  updateCartItems: (updatedCartItems: any[]) => void;
  showCartDropdown: boolean;
  setShowCartDropdown: (value: boolean) => void;
  forceShowCart: boolean;
};

const TopNavBar: React.FC<TopNavBarProps> = ({
  cartItems,
  removeItemFromCart,
  updateCartItems,
  showCartDropdown,
  setShowCartDropdown,
  forceShowCart
}) => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [AuthIsSignedIn, setAuthIsSignedIn] = useState(false);

  return (
    <Navbar bg="dark" variant="dark" expand="xl" sticky="top">
      <Container>
        <Navbar.Brand as={Link} to="/">
          Blind Night Records
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navcol-1" />
        <Navbar.Collapse id="navcol-1">
          <Nav className="ms-auto">
            <NavLink to="/" className="nav-link">home</NavLink>
            <NavLink to="/news" className="nav-link">news</NavLink>
            <NavLink to="/store" className="nav-link">store</NavLink>
            {/* <NavLink to="/artists" className="nav-link">artists</NavLink> */}
            <NavLink to="/contact" className="nav-link">contact</NavLink>

            {AuthIsSignedIn ? (
              <NavDropdown title={<BsPerson />}>
                <NavDropdown.Item as={Link} to="/orders">orders</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/account-settings">account settings</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/logout">logout</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <NavLink to="/login" className="nav-link">log in</NavLink>
                <NavLink to="/signup" className="nav-link">
                  <button className="btn btn-primary shadow">sign up</button>
                </NavLink>
              </>
            )}
            <Cart
              cartItems={cartItems}
              removeItemFromCart={removeItemFromCart}
              updateCartItems={updateCartItems}
              showCartDropdown={showCartDropdown}
              setShowCartDropdown={setShowCartDropdown}
              forceShowCart={forceShowCart}
            />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopNavBar;
