import React from "react";
import { BsQuestionCircle } from 'react-icons/bs';

export default function NotFound() {
    return (
        <section className="py-5">
            <div className="container py-5">
                <div className="row mb-4 mb-lg-5">
                    <div className="col-md-8 col-xl-6 text-center mx-auto">
                        <p className="fw-bold text-success mb-2"></p>
                        <h2 className="fw-bold">404</h2>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6 col-xl-4">
                        <div className="card">
                            <div className="card-body text-center d-flex flex-column align-items-center">
                                <div className="bs-icon-xl bs-icon-circle bs-icon-primary shadow bs-icon my-4">
                                    <BsQuestionCircle size={30} />
                                </div>
                                <h1>Not Found</h1>
                                <p>Sorry, the page you are looking for does not exist.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};