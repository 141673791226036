import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { BsPerson } from 'react-icons/bs';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            // Perform your login logic here
            // For instance, you can use the fetch API to send the data to the server
            const response = await fetch('your_login_endpoint', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });
            const data = await response.json();
            // Handle the response data and redirect if necessary
            if (data.success) {
                window.location.href = '/Home';
            } else {
                // Handle errors appropriately, such as displaying an error message
                console.log('Login unsuccessful');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    return (
        <section className="py-5">
            <div className="container py-5">
                <div className="row mb-4 mb-lg-5">
                    <div className="col-md-8 col-xl-6 text-center mx-auto">
                        <p className="fw-bold text-success mb-2"></p>
                        <h2 className="fw-bold">Login</h2>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6 col-xl-4">
                        <div className="card">
                            <div className="card-body text-center d-flex flex-column align-items-center">
                                <div className="bs-icon-xl bs-icon-circle bs-icon-primary shadow bs-icon my-4">
                                    <BsPerson size={30} />
                                </div>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            type="email"
                                            placeholder="Email"
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Control
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            type="password"
                                            placeholder="Password"
                                        />
                                    </Form.Group>

                                    <Button className='mb-3 w-100' variant="primary" type="submit">
                                        Log in
                                    </Button>
                                    <p className="text-muted">Forgot your password?</p>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
