import React, { useRef, useEffect } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BsFillBagFill, BsBag, BsPlus, BsDash } from 'react-icons/bs';
import './cart.css';
import { CartItem } from '../services/models';
import { Button } from 'react-bootstrap';

type CartProps = {
  cartItems: CartItem[];
  updateCartItems: (updatedCartItems: CartItem[]) => void;
  removeItemFromCart: (index: number) => void;
  showCartDropdown: boolean;
  setShowCartDropdown: (value: boolean) => void;
  forceShowCart: boolean;
};

const Cart: React.FC<CartProps> = ({ cartItems, updateCartItems, removeItemFromCart, showCartDropdown, setShowCartDropdown, forceShowCart }) => {
  const cartRef = useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Node;
    if (showCartDropdown && cartRef.current && !cartRef.current.contains(target) && target.nodeName !== 'INPUT' && !forceShowCart) {
      setShowCartDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCartDropdown, cartRef]);

  const handleIncreaseQuantity = (index: number) => {
    const updatedCart = [...cartItems];
    updatedCart[index].quantity += 1;
    updateCartItems(updatedCart);
  };

  const handleDecreaseQuantity = (index: number) => {
    const updatedCart = [...cartItems];
    if (updatedCart[index].quantity > 1) {
      updatedCart[index].quantity -= 1;
      updateCartItems(updatedCart);
    } else {
      removeItemFromCart(index);
    }
  };

  const calculateTotalPrice = () => {
    let totalCost = cartItems.reduce((total, item) => total + item.product.price * item.quantity, 0);
    return totalCost;
  };

  const renderCartItems = () => {
    return cartItems.map((item, index) => (
      <li key={index}>
        {item.product.name} - £{item.product.price} - Quantity:

        <Button className="btn quantity-buttons btn-sm btn-outline-secondary" onClick={() => handleDecreaseQuantity(index)}>
          <BsDash />
        </Button>
        {item.quantity}
        <Button className="btn quantity-buttons btn-sm btn-outline-secondary" onClick={() => handleIncreaseQuantity(index)}>
          <BsPlus />
        </Button>
      </li>
    ));
  };

  return (
    <NavDropdown
      ref={cartRef}
      title={cartItems.length > 0 ? <BsFillBagFill /> : <BsBag />}
      show={showCartDropdown || forceShowCart}
      onClick={() => { setShowCartDropdown(!showCartDropdown); }}
    >
      <NavDropdown.Header
        className={'cart-dropdown'}
        onClick={(e) => { e.stopPropagation(); }}
      >
        Cart
        <ul className="list-unstyled">{renderCartItems()}</ul>
        <div>Total: £{calculateTotalPrice()}</div>
        <input type="text" placeholder="Enter voucher code" />
        <Button className="btn-sm">Submit</Button>
        <br />
        <Button className="btn btn-primary">Checkout</Button>
      </NavDropdown.Header>
    </NavDropdown >
  );
};

export default Cart;
