import React from 'react';
import { Badge, Card, Col, Container, Row } from 'react-bootstrap';
import { releases } from '../data/testData';

export default function Catalogue() {


    return (
        <section className="py-5">
            <Container className="py-5">
                <Row mb={3}>
                    <Col md={3} xl={6} className="text-center mx-auto">
                        <h2 className="fw-bold">CATALOGUE</h2>
                        <p className="text-muted w-lg-50">Releases we've worked on</p>
                    </Col>
                </Row>
                <Row cols={3} md={3} className={'text-center justify-content-around d-flex'} >
                    {releases.map((release) => {
                        return (
                            <Card className="col-md-3 m-4 text-center" key={release.id}
                                // onClick={(e) => { handleOpenModal(product) }} 
                                style={{ minHeight: '30px' }
                                }>

                                <div>
                                    <div className="py-4">
                                        <p>{release.name}</p>
                                        <Badge className="bg-primary mb-2">{release.date}</Badge>
                                    </div>
                                </div>
                            </Card>
                        );
                    })}
                </Row>
            </Container>
        </section>
    );
};
