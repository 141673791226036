import React, { useState, useEffect } from 'react';

import Home from './pages/Home';
import News from './pages/News';
import Store from './pages/Store';
// import Artists from './pages/Artists';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Signup from './pages/Signup';
import AboutUs from './pages/About-Us';
import AccountSettings from './pages/account-settings';
import NotFound from './pages/404';
import Catalogue from './pages/Catalogue';
import Associates from './pages/associates';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import TopNavBar from './components/navbar';
import './bootstrap.min.css';
import Footer from './components/footer';
import MusicSubmission from './pages/Music-Submission';
import { CartItem, Product } from './services/models';
import { Container } from 'react-bootstrap';

export default function App() {

  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [showCartDropdown, setShowCartDropdown] = useState(false);
  const [forceShowCart, setForceShowCart] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [AuthIsSignedIn, setAuthIsSignedIn] = useState(false);

  const updateCartItems = (updatedCartItems) => {
    setCartItems(updatedCartItems);
  };

  const addItemToCart = (product: Product, size: String | null, colour: String | null, personalMessage: String | null) => {
    //find if this exact item and variation is already in basked, 
    const existingItemIndex = cartItems.findIndex((cartItem) => cartItem.productId === product.id && cartItem.size === size && cartItem.colour === colour && cartItem.personalMessage === personalMessage);
    if (existingItemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex].quantity += 1;
      setCartItems(updatedCartItems);
    } else {
      setCartItems((prevCartItems) => [...prevCartItems, { productId: product.id, quantity: 1, size: size, colour: colour, personalMessage: personalMessage, product: product } as CartItem]);
    }
  };

  const removeItemFromCart = (index: number) => {
    const updatedCartItems = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedCartItems);
  };



  // Load cart items from localStorage on component mount
  useEffect(() => {
    const storedCartItems = localStorage.getItem('cartItems');
    const validRoutes = ['/', '/news', '/store', '/artists', '/contact', '/login', '/signup', '/about-us', '/account-settings'];
    const currentPath = window.location.pathname;
    if (storedCartItems && storedCartItems !== "[]") {
      setCartItems(JSON.parse(storedCartItems));
    } else if (!validRoutes.includes(currentPath)) {
      setCartItems([]); // Empty the cart if the route is not valid and localStorage is empty
    }
  }, []);

  // Save cart items to localStorage whenever cartItems state changes
  useEffect(() => {
    if (cartItems.length > 0) {
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }
  }, [cartItems]);

  return (
    <Router>
      <TopNavBar
        cartItems={cartItems}
        removeItemFromCart={removeItemFromCart}
        updateCartItems={updateCartItems}
        showCartDropdown={showCartDropdown}
        setShowCartDropdown={setShowCartDropdown}
        forceShowCart={forceShowCart}
      />
      <Container style={{
        position: 'relative',
        minHeight: '60vh',
        width: '100%',
        zIndex: '1'
      }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/news" element={<News />} />
          <Route path="/store" element={<Store addItemToCart={addItemToCart} setShowCartDropdown={setForceShowCart} />} />
          {/* <Route path="/artists" element={<Artists />} /> */}
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/Catalogue" element={<Catalogue />} />
          <Route path="/associates" element={<Associates />} />
          <Route path="/contact" element={<Contact />} />

          {!AuthIsSignedIn && (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
            </>
          )}

          {AuthIsSignedIn && (
            <>
              <Route path="/account-settings" element={<AccountSettings />} />
              <Route path="/MusicSubmission" element={<MusicSubmission />} />
            </>
          )}

          {/* Fallback Route for undefined paths */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Container>
      <Footer />
    </Router>
  );

}