import React from 'react';
import { Container, Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Home.css'
import { homePageCarouselItems } from '../data/testData';

function Home() {

    const navigate = useNavigate();

    return (
        <Container className='d-flex justify-content-center'>
            <video autoPlay muted loop className="bg-video"
                style={{
                    position: 'fixed',
                    objectFit: 'cover',
                    width: '150%',
                    height: '100%',
                    // transform: 'translate(-25%,0)',
                    zIndex: '0'
                }}>
                <source src="assets/img/BNRcovervid.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <Carousel className='m-3' >
                {homePageCarouselItems.map((homepageItem) => {
                    return (
                        <Carousel.Item onClick={() => {
                            navigate(homepageItem.linkURL)
                        }}
                            style={{ cursor: 'pointer' }}>

                            <img
                                className="d-block w-100 homeCarouselImage align-self-center"
                                src={homepageItem.imageSrcURL}
                                alt={homepageItem.altText}

                            />
                            <Carousel.Caption>
                                <h3>{homepageItem.title}</h3>
                                <p>{homepageItem.caption}</p>
                            </Carousel.Caption>

                        </Carousel.Item>
                    );
                })}
            </Carousel>
        </Container >
    );
}
export default Home;
