import {
  CatalogueItem,
  NewsItem,
  Product,
  Associate,
  HomePageCarouselItem,
} from "../services/models";

export const newsItems: NewsItem[] = [
  {
    id: 1,
    link: "https://ffm.to/ava-die4you",
    imgSrc: "assets/img/news/Ava-square-die4y.jpg",
    date: "MARCH 2023",
    title:
      "Ava in the Dark release new single 'Die 4 You' with 7WestMusic + LabRecords",
    description:
      "Aired on Radio 1 Introducing Rock Show with Alyx Holcombe, playlisted on AppleMusic's 'New in Indie' worldwide",
  },
  {
    id: 2,
    link: "https://youthmusic.org.uk/original-track-award-group-22",
    imgSrc: "assets/img/news/YouthMusicAward-ava.png",
    date: "OCT 2022",
    title:
      "'Manic' - Ava in the Dark wins YouthMusic Award for Best Original Track (group) sponsored by Marshall Amps",
    description: "This release was made possible with support from Launchpad.",
  },
  {
    id: 3,
    link: "#",
    imgSrc: "assets/img/news/AmazonsClips.mp4",
    date: "OCT 2022",
    title: "Ava in the Dark supporting The Amazons on their UK Tour",
    description: "Part of BBC Music Introducing partnership",
  },
  {
    id: 3,
    link: "#",
    imgSrc: "https://www.facebook.com/focuswales/videos/740391110469736/",
    date: "MAY 2022",
    title: "Ava in the Dark at FOCUS WALES 2022",
    description:
      "Delete Us Forever was used as the background music for FOCUS Wales' highlights vid",
  },
  {
    id: 4,
    link: "https://youthmusic.org.uk/original-track-award-group-22",
    imgSrc: "assets/img/news/YouthMusicAward-ava.png",
    date: "OCT 2022",
    title:
      "'Manic' - Ava in the Dark wins YouthMusic Award for Best Original Track (group) sponsored by Marshall Amps",
    description: "This release was made possible with support from Launchpad.",
  },
  {
    id: 5,
    link: "#",
    imgSrc: "assets/img/news/AmazonsClips.mp4",
    date: "OCT 2022",
    title: "Ava in the Dark supporting The Amazons on their UK Tour",
    description: "Part of BBC Music Introducing partnership",
  },
  {
    id: 6,
    link: "#",
    imgSrc: "https://www.facebook.com/focuswales/videos/740391110469736/",
    date: "MAY 2022",
    title: "Ava in the Dark at FOCUS WALES 2022",
    description:
      "Delete Us Forever was used as the background music for FOCUS Wales' highlights vid",
  },
];

export const releases: CatalogueItem[] = [
  {
    id: 1,
    name: "Delete Us Forever EP",
    imgSrc: "",
    artist: "Ava in the Dark",
    date: "March 2020",
    contribution: "Recording, Production, Mixing, Mastering, PR",
    description:
      "BBC6 Radio play for 'Still', BBC Radio Wales A-List for 2 weeks for 'Delete Us Forever'",
  },
  {
    id: 2,
    name: "Affections",
    imgSrc: "",
    artist: "",
    date: "bleh",
    contribution: "",
    description: "",
  },
  {
    id: 3,
    name: "Product 3",
    imgSrc: "",
    artist: "",
    date: "bleh",
    contribution: "",
    description: "",
  },
  {
    id: 4,
    name: "Product 4",
    imgSrc: "",
    artist: "",
    date: "bleh",
    contribution: "",
    description: "",
  },
  {
    id: 5,
    name: "Product 5",
    imgSrc: "",
    artist: "",
    date: "bleh",
    contribution: "",
    description: "",
  },
  {
    id: 6,
    name: "Product 6",
    imgSrc: "",
    artist: "",
    date: "bleh",
    contribution: "",
    description: "",
  },
];

export const products: Product[] = [
  {
    id: 1,
    name: "Delete Us Forever EP",
    price: 10,
    category: "CD",
    artist: "Ava in the Dark",
    sizes: [],
    colorOptions: [],
    canPersonalMessage: true,
    outOfStock: false,
    isNew: true,
    imageUrls: ["../assets/img/products/1-1.png"],
    description:
      "Delete Us Forever EP by Ava in the Dark, released December 2021",
    isActive: true,
    onSale: false,
    isDigital: false,
  },
  {
    id: 2,
    name: "Ltd Edition Hand-Bleached Tee",
    price: 20,
    category: "Short Sleeve",
    artist: "Ava in the Dark",
    sizes: ["S", "M", "L", "XL"],
    colorOptions: ["Black"],
    canPersonalMessage: false,
    outOfStock: false,
    isNew: true,
    imageUrls: ["../assets/img/products/2-1.png"],
    description: "Hand painted bleached t-shirt, each slightly different",
    isActive: true,
    onSale: false,
    isDigital: false,
  },
  {
    id: 3,
    name: "Ltd Edition Hand-Bleached Long-sleeve",
    price: 15,
    category: "Long Sleeve",
    artist: "Artist 3",
    sizes: [],
    colorOptions: ["Purple", "Pink", "Orange"],
    canPersonalMessage: true,
    outOfStock: false,
    isNew: true,
    imageUrls: ["url7", "url8", "url9"],
    description: "description",
    isActive: true,
    onSale: false,
    isDigital: false,
  },
  {
    id: 4,
    name: "Product 4",
    price: 25,
    category: "Short Sleeve",
    artist: "Artist 1",
    sizes: ["S", "M", "L"],
    colorOptions: ["Yellow", "Brown", "Beige"],
    canPersonalMessage: true,
    outOfStock: false,
    isNew: false,
    imageUrls: ["url10", "url11", "url12"],
    description: "description",
    isActive: true,
    onSale: false,
    isDigital: false,
  },
  {
    id: 5,
    name: "Product 5",
    price: 30,
    category: "Long Sleeve",
    artist: "Artist 2",
    sizes: ["M", "L", "XL"],
    colorOptions: ["Cyan", "Magenta", "Teal"],
    canPersonalMessage: true,
    outOfStock: true,
    isNew: true,
    imageUrls: ["url13", "url14", "url15"],
    description: "description",
    isActive: true,
    onSale: true,
    isDigital: false,
  },
  {
    id: 6,
    name: "Product 6",
    price: 12,
    category: "Limited Edition",
    artist: "Artist 3",
    sizes: [],
    colorOptions: ["Silver", "Gold", "Bronze"],
    canPersonalMessage: true,
    outOfStock: false,
    isNew: false,
    imageUrls: ["url16", "url17", "url18"],
    description: "description",
    isActive: true,
    onSale: false,
    isDigital: false,
  },
  {
    id: 7,
    name: "Product 7",
    price: 18,
    category: "Accessories",
    artist: "Artist 1",
    sizes: [],
    colorOptions: ["Indigo", "Violet", "Maroon"],
    canPersonalMessage: true,
    outOfStock: false,
    isNew: true,
    imageUrls: ["url19", "url20", "url21"],
    description: "description",
    isActive: true,
    onSale: false,
    isDigital: false,
  },
];

export const associates: Associate[] = [
  {
    id: 1,
    name: "Steve",
    thumbnailImgSrc: "",
    URL: "",
    artists: [],
    description: "steve description",
  },
];

export const homePageCarouselItems: HomePageCarouselItem[] = [
  {
    id: 1,
    title: "Spread the Message",
    caption: "A hand-curated list of artists to support",
    imageSrcURL: "assets/img/artists/ava-in-the-dark.jpeg",
    linkURL: "/associates",
    active: true,
    altText: "spread the message slide",
  },
  {
    id: 2,
    title: "Submit your music",
    caption:
      "we're looking to work with more artists in Leeds, send us a link!",
    imageSrcURL: "assets/img/artists/ava-in-the-dark.jpeg",
    linkURL: "//MusicSubmission",
    active: true,
    altText: "submit your music slide",
  },
];
