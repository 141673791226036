import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { BsPerson } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function Signup() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSignup = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            // Perform your signup logic here
            const response = await fetch('your_signup_endpoint', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, email, password }),
            });
            const data = await response.json();
            // Handle the response data appropriately
            if (data.success) {
                // If signup is successful, you might redirect the user to the login page
                window.location.href = '/login';
            } else {
                // Handle errors appropriately, such as displaying an error message
                console.log('Signup unsuccessful');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    return (
        <div>
            <section className="py-5">
                <div className="container py-5">
                    <div className="row mb-4 mb-lg-5">
                        <div className="col-md-8 col-xl-6 text-center mx-auto">
                            <p className="fw-bold text-success mb-2">Sign up</p>
                            <h2 className="fw-bold">Welcome</h2>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-6 col-xl-4">
                            <div className="card">
                                <div className="card-body text-center d-flex flex-column align-items-center">
                                    <div className="bs-icon-xl bs-icon-circle bs-icon-primary shadow bs-icon my-4">
                                        <BsPerson size={30} />
                                    </div>
                                    <Form onSubmit={handleSignup}>
                                        <Form.Group className='mb-3' controlId="formName">
                                            <Form.Control
                                                type="text"
                                                placeholder="Name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group className='mb-3' controlId="formEmail">
                                            <Form.Control
                                                type="email"
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group className='mb-3' controlId="formPassword">
                                            <Form.Control
                                                type="password"
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Button variant="primary" type="submit" className="w-100">
                                            Sign up
                                        </Button>
                                        <p className="text-muted mt-3">
                                            Already have an account? &nbsp;
                                            <Link to="/Login">
                                                Log in
                                            </Link>
                                        </p>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Signup;