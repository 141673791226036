/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { ListGroup, Row, Col, Container } from 'react-bootstrap';
import { Product } from '../services/models';
import ProductCard from '../components/productCard';
import ProductModal from '../components/store/product-modal';
import { products } from '../data/testData';

type StoreProps = {
    addItemToCart: (product: Product, size: string | null, colour: string | null, personalMessage: string | null) => void;
    setShowCartDropdown: (value: boolean) => void;
};



function Store({ addItemToCart, setShowCartDropdown }: StoreProps) {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedArtist, setSelectedArtist] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const [selectedSize, setSelectedSize] = useState<string | null>(null);
    const [selectedColour, setSelectedColour] = useState<string | null>(null);
    const [personalMessage, setPersonalMessage] = useState<string | null>(null);

    const handleOpenModal = (product: Product) => {
        setSelectedProduct(product);
        setShowModal(true);
    };


    const handleCategorySelection = (category: string) => {
        if (selectedCategory === category) {
            setSelectedCategory('');
        } else {
            setSelectedCategory(category);
        }
    };

    const handleArtistSelection = (artist: string) => {
        if (selectedArtist === artist) {
            setSelectedArtist('');
        } else {
            setSelectedArtist(artist);
        }
    };

    // const handleIncreaseQuantity = (productId: number) => {
    //     const updatedCart = [...cartItems];
    //     const existingItem = updatedCart.find((item) => item.productId === productId);
    //     if (existingItem) {
    //         existingItem.quantity += 1;
    //         updateCartItems(updatedCart);
    //     }
    // };

    // const handleDecreaseQuantity = (productId: number) => {
    //     const updatedCart = [...cartItems];
    //     const existingItemIndex = updatedCart.findIndex((item) => item.productId === productId);
    //     if (existingItemIndex !== -1) {
    //         if (updatedCart[existingItemIndex].quantity > 1) {
    //             updatedCart[existingItemIndex].quantity -= 1;
    //             updateCartItems(updatedCart);
    //         } else {
    //             removeItemFromCart(existingItemIndex);
    //         }
    //     }
    // };


    const categories = Array.from(new Set(products.map((product) => product.category)));
    const artists = Array.from(new Set(products.map((product) => product.artist)));

    return (
        <section className="py-5">
            <Container className="py-5">
                {/* Left Sidebar */}
                <Row>
                    <Col md={2}>
                        <h5 className='m-3'>Categories</h5>
                        <ListGroup>
                            {categories.map((category, index) => (
                                <ListGroup.Item key={index} action onClick={() => handleCategorySelection(category)}>
                                    {category}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                        <h5 className='m-3'>Artists</h5>
                        <ListGroup>
                            {artists.map((artist, index) => (
                                <ListGroup.Item
                                    key={index}
                                    action
                                    onClick={() => handleArtistSelection(artist)}
                                >
                                    {artist}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                    {/* Product Display Section */}
                    <Col md={10}>
                        <Row xl={4} lg={4} md={2} sm={1} xs={1} className="mx-auto justify-content-around d-flex">
                            {products
                                .filter((product) => {
                                    // Filter products based on selectedCategory and selectedArtist
                                    if (selectedCategory && selectedCategory !== product.category) return false;
                                    if (selectedArtist && selectedArtist !== product.artist) return false;
                                    return true;
                                })
                                .map((product) => (
                                    <ProductCard product={product} handleOpenModal={handleOpenModal} />
                                ))}
                        </Row>
                    </Col>
                </Row>
                <ProductModal
                    selectedProduct={selectedProduct}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    selectedColour={selectedColour}
                    selectedSize={selectedSize}
                    addItemToCart={addItemToCart}
                    setShowCartDropdown={setShowCartDropdown}
                    personalMessage={personalMessage}
                />
            </Container >
        </section >
    );
}

export default Store;