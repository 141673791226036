import React from 'react';
import { FacebookProvider, EmbeddedPost } from 'react-facebook';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { newsItems } from '../data/testData';


function News() {
    return (
        <section className="py-5">
            <div className="container py-5">
                <div className="row mb-5">
                    <div className="col-md-8 col-xl-6 text-center mx-auto">
                        <h2 className="fw-bold">NEWS</h2>
                        <p className="text-muted w-lg-50">What've we been up to then?...</p>
                    </div>
                </div>
                <div className="row" style={{ width: '100%' }}>
                    <div className="col-md-8">
                        <div className="row row-cols-1 row-cols-md-2">
                            {newsItems.map((item, index) => (
                                <div className="col mb-4" key={index}>
                                    <div>
                                        {item.imgSrc.includes('.mp4') ? (
                                            // Render video
                                            <div className="rounded img-fluid shadow fit-cover" style={{ height: '250px', overflow: 'hidden' }} >
                                                <video autoPlay muted loop style={{ objectFit: 'cover', position: 'relative', left: '-15%', width: '130%', }} >
                                                    <source src={item.imgSrc} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        ) : item.imgSrc.includes('facebook.com') ? (
                                            // Render Facebook video
                                            <div className="rounded img-fluid shadow w-100 fit-cover" style={{ height: '250px', width: '100%', overflow: 'hidden' }}>
                                                <FacebookProvider appId="986400702461082">
                                                    <EmbeddedPost href={item.imgSrc} showText={false} width={'400px'} />
                                                </FacebookProvider>
                                            </div>
                                        ) : (
                                            // Render image
                                            <a href={item.link} target="_blank" rel="noopener noreferrer">
                                                <img className="rounded img-fluid shadow w-100 fit-cover" src={item.imgSrc} style={{ height: '250px' }} alt="news" />
                                            </a>
                                        )}
                                        <div className="py-4">
                                            <span className="badge bg-primary mb-2">{item.date}</span>
                                            <a href={item.link} target="_blank" rel="noopener noreferrer">
                                                <h4 className="fw-bold">{item.title}</h4>
                                            </a>
                                            <p className="text-muted">{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="col mb-4">
                            <div className="col mb-4" style={{ position: 'sticky', bottom: '0px' }}>
                                <TwitterTimelineEmbed
                                    sourceType="profile"
                                    screenName="RecordsBlind"
                                    options={{ height: 1000, width: 400 }}
                                    theme="dark"
                                    noHeader
                                    noScrollbar
                                />
                            </div>
                        </div>
                        <div className="col mb-4">
                            {/* insta here */}
                        </div>
                    </div>
                </div>
            </div >
        </section >
    );
};

export default News;