import React from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { Product } from "../../services/models";

type ProductModalProps = {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    selectedProduct: Product;
    addItemToCart: (product: Product, size: String | null, colour: String | null, personalMessage: String | null) => void;
    setShowCartDropdown: (value: boolean) => void;
    selectedSize: string;
    selectedColour: string;
    personalMessage: string;
};


export default function ProductModal({ selectedProduct, showModal, setShowModal, addItemToCart, setShowCartDropdown, selectedSize, selectedColour, personalMessage }: ProductModalProps) {

    const handleAddToCart = (product: Product, size: String | null, colour: String | null, personalMessage: String | null) => {
        addItemToCart(product, size, colour, personalMessage);
        setShowCartDropdown(true);
        setTimeout(() => {
            setShowCartDropdown(false);
        }, 3000);
        handleCloseModal(); // Close the modal after adding to cart
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };



    return (
        <Modal show={showModal} onHide={handleCloseModal}>
            {selectedProduct && (
                <div>

                    <Modal.Header closeButton>
                        <Modal.Title>{selectedProduct.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <img src={selectedProduct.imageUrls[0]} alt={selectedProduct.name} style={{ maxWidth: '100%' }} />
                        <p>{selectedProduct.artist}</p>
                        <p>{selectedProduct.category}</p>
                        <p>{selectedProduct.price}</p>
                        <p>{selectedProduct.description}</p>
                        <Row className="align-items-center">
                            <Col>
                                {selectedProduct.outOfStock
                                    ? <Button onClick={() => { console.log("notify") }}>Notify</Button>
                                    : <Button onClick={() => handleAddToCart(selectedProduct, selectedSize, selectedColour, personalMessage)}>Add to Cart</Button>
                                }
                            </Col>
                        </Row>
                    </Modal.Body>
                </div>
            )}
        </Modal>
    )
};