import React, { useState } from "react";
import { Form, Button, Col } from "react-bootstrap";
import { BsEnvelope, BsInstagram } from "react-icons/bs";

const Contact: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Add your logic for handling the form submission here
  };

  return (
    <section className="py-5">
      <div className="container py-5">
        <div className="row mb-5">
          <div className="col-md-8 col-xl-6 text-center mx-auto">
            <p className="fw-bold text-success mb-2">Contact</p>
            <h2 className="fw-bold">How you can reach us</h2>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-6 col-xl-4">
            <div>
              <Form className="p-3 p-xl-4" onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    rows={6}
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="shadow d-block w-100">
                  Send
                </Button>
              </Form>
            </div>
          </div>
          <Col md={4} xl={4} className="d-flex justify-content-center justify-content-xl-start">
            <div className="d-flex flex-wrap flex-md-column justify-content-md-start align-items-md-start h-100">
              <div className="d-flex align-items-center p-3">
                <div className="bs-icon-md bs-icon-circle bs-icon-primary shadow d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block bs-icon bs-icon-md">
                  <BsEnvelope />
                </div>
                <div className="px-2">
                  <h6 className="fw-bold mb-0">Email</h6>
                  <p className="text-muted mb-0">info@blindnightrecords.com</p>
                </div>
              </div>
              <div className="d-flex align-items-center p-3">
                <div className="bs-icon-md bs-icon-circle bs-icon-primary shadow d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block bs-icon bs-icon-md">
                  <BsInstagram />
                </div>
                <div className="px-2">
                  <h6 className="fw-bold mb-0">Insta</h6>
                  <p className="text-muted mb-0">@blindnightrecords</p>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </div>
    </section>
  );
};

export default Contact;
